import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../ass/img/logo.png';
import logo2 from '../ass/img/logo2.png'

const Navbar = () => {
  const handleClick = () => {
    const email = 'support@gmail.com';
    const subject = 'Subject of the email';
    const body = 'Body of the email';

    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  const handlePhoneClick = () => {
    const phoneNumber = '7448669002';
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <div>
      <nav className='navbar navbar-expand-sm navbar-dark  py-1' style={{backgroundColor:'black', color:'', fontWeight:'bolder'}}>
        <div className='container'>
          <Link className='navbar-brand' to='/'>
            <img src={logo} alt="Logo" style={{ width: '50px' }} className='' />
            <img src={logo2} alt="Logo" style={{ width: '150px' }} className='' />
          </Link>
          <button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navbarNav'>
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id="navbarNav">
            <ul className='navbar-nav ms-auto'>
              <li className='nav-item'>
                <Link className='nav-link' to='/'>Home</Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/about'>About</Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/service'>Services</Link>
              </li>
              {/* <li className='nav-item'>
                <Link className='nav-link' to='/career'>Careers</Link>
              </li> */}
              <li className='nav-item'>
                <Link className='nav-link' to='/contact'>Contact</Link>
              </li>
            </ul>
            <ul className='navbar-nav'>
              <li className='nav-item'>
                <a className='nav-link' href='#' style={{ cursor: 'pointer' }} onClick={handleClick}>support@Peoplefully.com</a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='#' style={{ cursor: 'pointer' }} onClick={handlePhoneClick}>7448669002</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
