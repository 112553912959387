import React from 'react';
import { Container } from 'react-bootstrap';

function Temp(){
    return(
        
            <Container fluid ='md'>
                <div className="p-3 mb-2 text-white rounded" style={{backgroundColor:'#2a2b2f'}}>
                    <h2>
                        Temporary Staffing
                    </h2>
                        <p className='text-start'>
                                Transform your workforce with People Fully Co-Employment-Based Temporary Staffing. In this collaborative approach, skilled employees from our agency seamlessly integrate into your company for specified projects, under your supervision. Benefit from immediate access to qualified talent, flexible staffing options, and cost-effective workforce management. Streamline HR functions with our solution, optimizing resources for your projects. Elevate your workforce strategy with People Fully HR Services Temp Staffing—efficiency meets flexibility. Contact us today to optimize your staffing solutions and streamline HR processes.
                        </p>
                </div>
            </Container>

        
    );
}

export default Temp;