import React from 'react';
import { Container } from 'react-bootstrap';

function PCS(){
    return(
        
            <Container fluid ='md'>
                <div className="p-3 mb-2   rounded text-white" style={{backgroundColor:'#2a2b2f'}}>
                    <h2>
                        Payroll & Compliance Services
                    </h2>
                        <p className='text-center'>
                            Experience precision and timeliness in salary processing with People Fully HR Services Payroll Services. Leveraging robust technology and industry-best HR practices, we ensure that your employees receive their salaries accurately and on time. Our commitment is to seamlessly manage payroll, allowing you to focus on what matters most - your business.
                        </p>
                        
                        <p className='text-center'>
                            At People Fully HR Services, our Compliance Services are designed to keep your statutory books up-to-date in accordance with industry standards. We are dedicated to supporting organizations in adhering to government norms, ensuring they become the best in compliance. Trust us to navigate the complexities, so you can operate with confidence, knowing that all compliance norms are diligently adhered to.
                         </p>
                       <p className='text-center'> 
                            Choose People Fully HR Services for Payroll and Compliance - where accuracy, timeliness, and adherence to industry standards are our top priorities. Contact us today for streamlined and compliant HR solutions.
                        </p>
                </div>
            </Container>

        
    );
}

export default PCS;