import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const AboutUs = () => {
  return (
    <Container>
      <Row className=' align-items-center'>
        <Col>
          <div>
            <p className='h2' style={{color:"white"}}>About us</p>
          </div>
        </Col>

        <Col xs={12} sm={10}>
          <Card className='mb-2 p-5  rounded-pill ' style={{backgroundColor:'#2a2b2f', color:'white'}}>
            <Card.Body>
              At People Fully, we understand that your most valuable asset is your people. That's why we are dedicated to providing comprehensive and tailored HR solutions to help your business thrive. With a commitment to excellence, innovation, and personalized service, we stand as your trusted partner in navigating the dynamic landscape of human resources.
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUs;
