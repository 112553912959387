// HomePage.js

import React from 'react';
import AboutUs from '../Components/AboutUs';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from '../Components/Footer';
import img1 from '../ass/img/img1.png'
import img2 from '../ass/img/img2.png'



const About = () => {
  return (
    <div className='about-bg'>
    <div>
      <AboutUs />
      <Container className='mb-5 '>
      <h1 className='m-3' style={{color:'white'}}>Our Missiom</h1>
        <Row className=' d-flex align-items-center shadow rounded mb-5 ' style={{backgroundColor:'white', opacity:'0.5'}}>
          
          <Col className='m-4 text-black  rounded '>
          
            <div className=''> 
            <h4 className='fs-4 p-3 text-black text-bold'>Empowering Your Success Through Exceptional HR Services</h4>
            </div>
            <p className='text-start'>
                At the core of our mission is the belief that a company's success is deeply intertwined with the well-being and effectiveness of its workforce. Our mission is to empower organizations of all sizes by delivering top-notch HR solutions that foster employee engagement, streamline processes, and drive business growth.
            </p>
          </Col>
          <Col className='' md="auto">

            <img src={img1} style={{width:'300px'}} className='rounded'></img>
          </Col>
          </Row>
        <Row className='d-flex align-items-center shadow rounded mb-5' style={{backgroundColor:' #2a2b2f'}} >
          
              <Col className=' m-4 text-white  rounded '>
              
                <div className=''> 
                <h4 className='fs-4 p-3'>Empower Your Workforce with People Fully HR Services</h4>
                </div>
                <p className='text-start'>
                    Temporary & Contract Staffing to strategic Permanent Recruitment, streamlined Recruitment Process Outsourcing, and impactful Training & Development, we elevate your workforce. With a focus on precision in Payroll & Compliance Services, tailored solutions, strategic partnerships, innovation, and proven expertise define our commitment.
                </p>
              </Col>
              <Col className='' md='auto'>

                <img src={img2} style={{width:'300px'}} className='rounded'></img>
              </Col>
          </Row>
        <Row className=' d-flex align-items-center shadow rounded mb-5 'style={{backgroundColor:'white', opacity:'0.5'}}>
          
              <Col className='m-4 text-black  rounded '>
              
                <div className=''> 
                <h4 className='fs-4 p-3'>Let's Build Success Together</h4>
                </div>
                <p className='text-start'>
                    People Fully HR Services is not just a service provider; we are your dedicated partner in achieving HR excellence. Whether you are a small business or a large enterprise, we are here to support your journey towards building a thriving and engaged workforce.
                    Contact us today to explore how People Fully HR Services can contribute to the success and growth of your organization.
                </p>
              </Col>
              <Col className='p-2' md='auto'>

                <img src={img1} style={{width:'300px'}} className='rounded'></img>
              </Col>
          </Row>
      </Container>
      <Footer />
    </div>
    </div>
  );
}

export default About;