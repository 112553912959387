import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';

// const firebaseConfig = {
//   apiKey: "AIzaSyBgXmLYLkPC-Jx7icvNShze88Vc7BJaUaE",
//   authDomain: "peoplefully-e9888.firebaseapp.com",
//   projectId: "peoplefully-e9888",
//   storageBucket: "peoplefully-e9888.appspot.com",
//   messagingSenderId: "607354370236",
//   appId: "1:607354370236:web:8566312173f9edae07f8b9",

// };
// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const firestore = getFirestore(app); // Initialize Firestore if needed

const root = createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
