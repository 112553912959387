import React from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-black text-light py-4">
      <Container>
        <Row>
          <Col md={4} sm={6} className='text-start'>
            <h5>Quick Links</h5>
            <ListGroup as='ul'>
              <ListGroup.Item as='li' className='bg-black border-0'>
                <Link className='nav-link text-light' to='/'>Home</Link>
              </ListGroup.Item>
              <ListGroup.Item as='li' className='bg-black border-0'>
                <Link className='nav-link text-light' to='/about'>About</Link>
              </ListGroup.Item>
              <ListGroup.Item as='li' className='bg-black border-0'>
                <Link className='nav-link text-light' to='/service'>Services</Link>
              </ListGroup.Item>
              {/* <ListGroup.Item as='li' className='bg-dark border-0'>
                <Link className='nav-link text-light' to='/career'>Career</Link>
              </ListGroup.Item> */}
              <ListGroup.Item as='li' className='bg-black border-0'>
                <Link className='nav-link text-light' to='/contact'>ContactUs</Link>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={4} sm={6} className='text-start'>
            <h5>Services</h5>
            <ListGroup>
              <ListGroup.Item as='li' className='bg-black border-0'>
                <Link className='nav-link text-light' to='/service'>Temporary Staffing</Link>
              </ListGroup.Item>
              <ListGroup.Item as='li' className='bg-black border-0'>
                <Link className='nav-link text-light' to='/service'>Permanent Staffing</Link>
              </ListGroup.Item>
              <ListGroup.Item as='li' className='bg-black border-0'>
                <Link className='nav-link text-light' to='/service'>Learning & Development</Link>
              </ListGroup.Item>
              <ListGroup.Item as='li' className='bg-black border-0'>
                <Link className='nav-link text-light' to='/service'>Payroll & Compliance Services</Link>
              </ListGroup.Item>
              <ListGroup.Item as='li' className='bg-black border-0'>
                <Link className='nav-link text-light' to='/service'>Facility Management Solutions</Link>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={4} sm={12} className='text-start'>
            <h5>Contact</h5>
            <ListGroup>
              <ListGroup.Item as='li' className='bg-black text-white border-0'>
               Email: support@Peoplefully.com
              </ListGroup.Item>
              <ListGroup.Item as='li' className='bg-black text-white border-0'>
                Phone: 7448669002
              </ListGroup.Item>
              <ListGroup.Item as='li' className='bg-black text-white border-0'>
                Address: No 12, Parthasarathy Nagar, Bounded Street, Ayyappakkam, Ambathur Tiruvallur - 600077
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
