import Carousel from 'react-bootstrap/Carousel';
import band2 from '../ass/img/band2.png';
import band3 from '../ass/img/band3.png';
import band1 from '../ass/img/band1.png';
import Container from 'react-bootstrap/esm/Container';

function Banner() {
  return (

    <Carousel className='p-2 rounded'>
      <Carousel.Item interval={1000}>
        <img src = {band1} className='d-block w-100 ' />
      </Carousel.Item>
      <Carousel.Item interval={500}>
         <img src = {band2} className='d-block w-100' />
      </Carousel.Item>
      <Carousel.Item>
         <img src = {band3} className='d-block w-100' />
      </Carousel.Item>
    </Carousel>

  );
}

export default Banner;