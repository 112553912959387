import React from "react";
import Temp from "../Components/Temp";
import Perm from "../Components/Perm";
import LD from "../Components/LD";
import RP from "../Components/RP";
import img4 from '../ass/img/img4.png'
import { Container } from "react-bootstrap";
import PCS from "../Components/PCS";
import FMS from "../Components/FMS";
import Footer from "../Components/Footer";

function Service(){
    return(
        <div className="serv-bg">
            <Container className="mb-3 ">
                <div className="">
                    <img src={img4} style={{width:'600px'}} className="img-fluid m-3"></img>
                </div>
            <Temp />
            <Perm />
            <LD />
            <RP />   
            <PCS />
            <FMS/>
            </Container>
            <Footer />
            
        </div>
    );
}

export default Service;