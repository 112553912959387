import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';


function FMS(){
    return(
        
            <Container>
                <div  className="p-3 mb-2 rounded text-black" style={{backgroundColor:'white', opacity:'0.5'}}>

               
                        <h2>
                        Facility Management Solutions
                        </h2>
                        <p className='text-start'>
                        At People Fully Facility Management, we offer end-to-end facility management services catering to Corporate entities, Business Centers, Shopping Malls, Hospitals, and various industries. Our commitment is to create seamless and efficient environments, ensuring optimal functionality for your facilities.

                        </p>
                </div>
            </Container>
        
    );
}

export default FMS;