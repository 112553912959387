import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import add from '../ass/img/add.png';
import call from '../ass/img/call.png';
import mail from '../ass/img/mail.png';

const Add = () => {
  return (
    <Container className='p-4 mb-4 rounded'>
      <Row className='justify-content-center align-items-center'>
        <Col lg={10}>
          <Card className='p-3 mb-2 shadow text-white rounded ' style={{backgroundColor:'#2a2b2f'}}>
            <Card.Body>
              <Row className='justify-content-center align-items-center'>
                <Col className='m-0' lg={2}>
                  <img src={add} alt="Address" style={{ width: "50px" }} />
                </Col>
                <Col>
                  <div className='text-start'>
                    <h3 className='fw-bold'>PeopleFully</h3>
                    <p>No 12, Parthasarathy Nagar, Bounded Street, Ayyappakkam, Ambathur Tiruvallur - 600077</p>
                  </div>
                </Col>
              </Row>
              <Row className='justify-content-center align-items-center mb-4'>
                <Col className='m-0' lg={2}>
                  <img src={call} alt="Call" style={{ width: "50px" }} />
                </Col>
                <Col>
                  <div className='text-start'>
                    <h6 className='fw-bold'>7448669002</h6>
                  </div>
                </Col>
              </Row>
              <Row className='justify-content-center align-items-center'>
                <Col className='m-0' lg={2}>
                  <img src={mail} alt="Email" style={{ width: "50px" }} />
                </Col>
                <Col>
                  <div className='text-start'>
                    <h6 className='fw-bold'>support@Peoplefully.com</h6>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Add;
