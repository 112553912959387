import React from 'react';
import { Container } from 'react-bootstrap';

function RP(){
    return(
        
            <Container fluid ='md'>
                <div className="p-3 mb-2 rounded text-black" style={{backgroundColor:'white', opacity:'0.5'}}>
                    <h2>
                        Recruitment Process Outsourcing

                    </h2>
                        <p className='text-start'>
                        In the rapidly evolving landscape of Human Resources Outsourcing (HRO), Recruitment Process Outsourcing (RPO) stands out as a dynamic and essential segment. At People Fully HR Services, we transcend traditional outsourcing; we become the virtual extension of your Human Resources department.</p>
                        
                        <p className='text-start'>Our expertise lies in providing high-volume, end-to-end recruitment services. With a commitment to excellence, we dedicate a specialist team to seamlessly support various stages of the hiring process. From candidate sourcing to onboarding, we take ownership of the entire recruitment lifecycle, ensuring a streamlined and efficient experience. </p>
                       <p className='text-start'> By partnering with People Fully, your organization gains more than just recruitment services; you gain a strategically dedicated to generating significant savings in time, efficiency, and resources. Elevate your hiring processes with our comprehensive RPO solutions - where expertise meets efficiency. Contact us today for a transformative recruitment partnership.

                        </p>
                </div>
            </Container>

        
    );
}

export default RP;