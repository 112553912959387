// HomePage.js

import React from 'react';
import Banner from '../Components/Banner';
import AboutUs from '../Components/AboutUs';
// import ContactUs from '../Components/ContactUs';
import Services from '../Components/Services';
import Footer from '../Components/Footer';
import Card from '../Components/Cards';




const Home = () => {
  return (
    <div className='home-bg'>
     
      <Banner />
      <AboutUs />
      <Card />
      {/* <Services /> */}
      {/* <ContactUs /> */}
      <Footer />
     
    </div>
  );
}

export default Home;
