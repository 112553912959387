import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    email: '',
    phoneNumber: '', // Ensure phoneNumber is treated as a string
    message: ''
  });
  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await fetch('http://localhost:5154/api/Email/send', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          alert('Email sent successfully!');
          setFormData({
            firstName: '',
            email: '',
            phoneNumber: '',
            message: ''
          });
        } else {
          const error = await response.text();
          alert(`Error sending email: ${error}`);
        }
      } catch (error) {
        console.error('Error sending email:', error);
        alert('Error sending email');
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = (formData) => {
    const errors = {};
    if (!formData.firstName.trim()) {
      errors.firstName = 'First name is required';
    }
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email address is invalid';
    }
    if (!formData.phoneNumber.trim()) { // Ensure phoneNumber is treated as a string
      errors.phoneNumber = 'Phone number is required';
    } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
      errors.phoneNumber = 'Phone number must be 10 digits';
    }
    if (!formData.message.trim()) {
      errors.message = 'Message is required';
    }
    return errors;
  };

  return (
    <div>
      <Container className='p-4 mb-4 rounded bg-primary' >
        <Row className='d-flex justify-content-center align-items-center'>
          <Col lg={8}>
            <Card className='p-3 mb-2 shadow text-white rounded bg-primary'>
              <Card.Body className='fw-bold'>At People Fully, we understand that your most valuable asset is your people. That's why we are dedicated to providing comprehensive and tailored HR solutions to help your business thrive. With a commitment to excellence, innovation, and personalized service, we stand as your trusted partner in navigating the dynamic landscape of human resources.
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <h2 className='text-white'>Contact Form</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formFirstName">
                <Form.Label className='text-white'>First Name:</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  isInvalid={!!errors.firstName}
                />
                <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label className='text-white'>Email:</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formPhoneNumber">
                <Form.Label className='text-white'>Phone Number:</Form.Label>
                <Form.Control
                  type="tel"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  isInvalid={!!errors.phoneNumber}
                />
                <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formMessage">
                <Form.Label className='text-white'>Message:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  isInvalid={!!errors.message}
                />
                <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
              </Form.Group>
              <Button  type="submit" className='mt-3 shadow ' >
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUs;
