import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';


function LD(){
    return(
        
            <Container>
                <div  className="p-3 mb-2  text-white" style={{backgroundColor:'#2a2b2f'}}>

               
                        <h2>
                        Learning & Development
                        </h2>
                        <p className='text-start'>
                        Embark on a transformative learning journey with People Fully HR Services, a leading specialist in Learning & Development across diverse sectors. Our dynamic team of highly driven professionals is dedicated to making learning an engaging, enjoyable, and interactive experience. With a commitment to quality, our tailor-made training programs cover essential skills, from Executive Coaching and Leadership Development to Basic Communication Skills, ensuring relevance at every organizational level. Whether you're an individual, part of a corporate team, or an educational institution, our programs cater to your unique needs. From half-day workshops to yearlong continuity programs, we offer versatile learning solutions in both classroom and online settings. Explore our expertise in Communicative English, Business English, Voice & Accent, Corporate Training, E-mail & Telephone Etiquettes, BPO Training, Placement Training, Behavioural & Soft Skills, and more. At People Fully, quality is not just a commitment; it's a promise to elevate your learning experience

                        </p>
                </div>
            </Container>
        
    );
}

export default LD;