import './App.css';
import Navbar from './Components/Navbar';
import Home from './Pages/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './Pages/About';
import Service from './Pages/Serivce';
import Temp from './Components/Temp';
import Perm from './Components/Perm';
import LD from './Components/LD';
import Career from './Pages/Career';
import Contact from './Pages/Contact';



function App() {
  return (
    <div className="App">
      <Router>
      <Navbar />
  
          <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/service" element={<Service />} />
              <Route exact path="/career" element={<Career />} />
              <Route exact path=":id" element={<Temp />} />
              <Route exact path=":id" element={<Perm />} />
              <Route exact path=":id" element={<LD />} />
              <Route exact path="/contact" element={<Contact/>}/>
              
          </Routes>
      </Router>
    
    </div>
  );
}

export default App;
