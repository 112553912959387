// HomePage.js

import React from 'react';
// import Address from '../Components/Address';
import ContactUs from '../Components/ContactUs';
import Footer from '../Components/Footer';
import Add from '../Components/Add';



const Contact = () => {
  return (
    <div className=' cont-bg'>
   
      {/* <Address /> */}
      <Add />
      <Footer />
    </div>
  );
}

export default Contact;
