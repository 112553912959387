import React from "react";
import { Container } from "react-bootstrap";



function Perm(){
    return(
        <Container fluid ='md'>
            
                <div className="p-3 mb-2  text-dark rounded " style={{backgroundColor:'white', opacity:'0.5'}} > 
                <h2>
                    permanant Staffing
                </h2>
                    <p className='text-start'>
                    Discover seamless hiring with Permanent Staffing solutions. We connect job seekers with prospective employers, facilitating permanent, full-time employment. Enjoy simplicity with a one-time fee, placing skilled individuals directly on your payroll from day one. Leverage our industry expertise for personalized hiring solutions that meet all your staffing requirements.
                    Elevate your hiring experience with People Fully. Let us simplify your permanent staffing needs.

                    </p>
                </div>
            
            
        </Container>
    );
}

export default Perm;