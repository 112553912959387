import React from 'react';
import './card.css'; // Import the CSS file with the provided styles
import { Link } from 'react-router-dom';

const Card = () => {
  return (
    <div class="containers">
    <div class="car">
      <div class="box">
        <div class="content">
          {/* <h2>01</h2> */}
          <h3>Temporary Staffing</h3>
          <p> Transform your workforce with People Fully Co-Employment-Based Temporary Staffing. In this collaborative staffing solutions...</p>
          <Link className='nav-link' to='/service'>Read More</Link>
        </div>
      </div>
    </div>
  
    <div class="car">
      <div class="box">
        <div class="content">
          {/* <h2>02</h2> */}
          <h3>Permanent Recruitment</h3>
          <p>Discover seamless hiring with Permanent Recruitment solutions. We connect job seekers with prospective employers, facilitating permanent,..</p>
          <Link className='nav-link' to='/service'>Read More</Link>
        </div>
      </div>
    </div>
  
    <div class="car">
      <div class="box">
        <div class="content">
          {/* <h2>03</h2> */}
          <h3>Learning & Development</h3>
          <p>Embark on a transformative learning journey with People Fully HR Services, a leading specialist in  Learning & Developmen</p>
          <Link className='nav-link' to='/service'>Read More</Link>
        </div>
      </div>
    </div>
    <div class="car">
      <div class="box">
        <div class="content">
          {/* <h2>04</h2> */}
          <h3>Recruitment Process Outsourcing</h3>
          <p>In the rapidly evolving landscape of Human Resources Outsourcing (HRO), Recruitment Process Outsourcing (RPO) stands out as a dynamic and essential segment...  </p>
          <Link className='nav-link' to='/service'>Read More</Link>
        </div>
      </div>
    </div>
    <div class="car">
      <div class="box">
        <div class="content">
          {/* <h2>05</h2> */}
          <h3>Payroll & Compliance Services</h3>
          <p> Experience precision and timeliness in salary processing with People Fully HR Services Payroll Services. Leveraging robust technology and industry-best HR practices,..</p>
          <Link className='nav-link' to='/service'>Read More</Link>
        </div>
      </div>
    </div>
    <div class="car">
      <div class="box">
        <div class="content">
          {/* <h2>06</h2> */}
          <h3>Facility Management Solutions</h3>
          <p>At People Fully Facility Management, we offer end-to-end facility management services catering to Corporate entities, Business Centers, Shopping Malls, Hospitals,..</p>
          <Link className='nav-link' to='/service'>Read More</Link>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Card;
